@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/HelveticaNeue.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Bold';
    src: url('../fonts/HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'UTM Bebas';
    src: url('../fonts/UTM-Bebas.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@mixin truncate($line: 2) {
    overflow: hidden;
    max-width: 100%;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical; 
    margin: 0 auto;
    text-overflow: ellipsis;
}

body {
    font-family: 'Helvetica', Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background: #eeffff;
}

.card {
    background: #eeffff;
}

a, a:hover {
    text-decoration: none;
    color: $cl-black;
    transition: all .2s ease;
    cursor: pointer;
}

a:hover {
    color: $cl-green;
}

.text-primary {
    color: $cl-blue2 !important;
}

.page-link {
    background-color: transparent;
}

.tab-news {
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 39px;
        height: 5px;
        width: 100%;
        background: $cl-blue;
        z-index: 2;
    }

    &-gradient {
        &::after {
            background: rgb(0,123,115);
            background: linear-gradient(90deg, rgba(0,123,115,1) 0%, rgba(129,190,186,1) 35%, rgba(255,255,255,1) 100%);
        }
    }
}

.nav-pills {
    .nav-item {
        position: relative;

        &:first-child {
            z-index: 2;

            .nav-link {
                background-color: $cl-green;
            }
        }

        &:last-child {
            .nav-link {
                background-color: $cl-blue2;
                padding-left: 40px;

                &::after {
                    border-bottom: 44px solid $cl-blue2;
                }
            }
        }
    }

    .nav-link {
        border-radius: 0;
        background: $cl-blue2;
        color: $cl-white;
        position: relative;
        padding: 0px 16px 4px;
        text-transform: uppercase;
        font-size: 24px;
        transition: all .2s ease;

        &::before {
            position: absolute;
            content: '';
            bottom: 4px;
            left: 16px;
            height: 1px;
            background-color: $cl-white;
            transition: all .2s ease;
        }

        &::after {
            position: absolute;
            content: '';
            top: 0;
            right: -32px;
            width: 0;
            height: 0;
            border-bottom: 44px solid $cl-green;
            border-right: 32px solid transparent;
            transition: all .2s ease;
        }

        &.active {
            background: transparent;
            border-radius: 0;

            &::before {
                width: 100%;
            }
        }
    }
}

.home-tabs-news {
    .nav-pills {
        .nav-item {
            position: relative;

            .nav-link {
                &::before {
                    position: absolute;
                    content: '';
                    bottom: 4px;
                    left: 16px;
                    height: 1px;
                    background-color: $cl-white;
                    transition: all .2s ease;
                }

                &.active {
                    &::before {
                        width: 100%;
                    }
                }
            }
    
            &:first-child {
                z-index: 3;

                .nav-link {
                    background-color: $cl-green;

                    &::after {
                        border-bottom: 40px solid $cl-green;
                    }
                }
            }

            &:nth-child(2) {
                z-index: 2;

                .nav-link {
                    background-color: $cl-blue2;

                    &::after {
                        border-bottom: 40px solid $cl-blue2;
                    }
                }
            }
    
            &:last-child {
                z-index: 1;
                .nav-link {
                    background-color: $cl-green2;

                    &::after {
                        border-bottom: 44px solid $cl-green2;
                    }
                }
            }

            &:not(:first-child) {
                .nav-link {
                    padding-left: 40px;
                }
            }
        }
    }
}

.owl-dots {
    position: absolute;
    right: 16px;
    bottom: 80px;
}

.owl-dot {
    width: 10px;
    height: 10px;
    background: rgba(255,255,255, .9)!important;
    margin-right: 8px;
    border-radius: 50%;

    &.active {
        background: $cl-blue2 !important;
    }
}

.card-img,
.news-item-img { 
    position: relative;
    overflow: hidden;

    > a {
        position: relative;
        overflow: hidden;
    }

    img {
        transform: scale(1);
        transition: all .25s ease;
    }
}

.card {
    &:hover .card-img img {
        transform: scale(1.05);
    }

    &:hover .news-item-img img {
        transform: scale(1.2);
    }
}

.card-divided {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: $cl-blue2;

    &-red {
        background: $cl-red;
    }

    &-green {
        background: $cl-green;
    }

    &-green-light {
        background: $cl-green3;
    }
}

.card-category {
    background: $cl-blue;
    position: absolute;
    width: 100%;
    color: $cl-white;
    left: 0;
    bottom: 0;
    padding: 4px;

    &-horizontal {
        background: $cl-blue2;
        position: absolute;
        color: $cl-white;
        left: 0;
        bottom: 0;
        padding: 4px;

        &::after {
            position: absolute;
            content: '';
            top: 0;
            right: -20px;
            width: 0;
            height: 0;
            border-bottom: 29px solid $cl-blue2;
            border-right: 20px solid transparent;
            transition: all .2s ease;
        }
    }


    &-horizontal-red {
        background: $cl-red;

        &::after {
            border-bottom: 29px solid $cl-red;
        }
    }
    &-horizontal-green {
        background: $cl-green;

        &::after {
            border-bottom: 29px solid $cl-green;
        }
    }
    &-horizontal-green-light {
        background: $cl-green3;

        &::after {
            border-bottom: 29px solid $cl-green3;
        }
    }
}

@media (min-width: 1367px) {
    .container {
        max-width: 95%;
    }
}

@media (min-width: 1441px) {
    .container {
        max-width: 75%;
    }
}

.page-heading {
    position: relative;
    margin-bottom: 10px;
    
    h2 {
        background: $cl-green2;
        color: $cl-white;
        display: inline-block;
        margin-bottom: 5px;
        padding: 6px 8px 8px;
        font-size: 24px;
        position: relative;
        margin-bottom: 0;

        &::after {
            position: absolute;
            content: '';
            top: 0;
            right: -32px;
            width: 0;
            height: 0;
            border-bottom: 42px solid $cl-green2;
            border-right: 32px solid transparent;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 5px;
        width: 100%;
        background: $cl-green2;
    }

    &.page-heading-blue {
        &::after {
            background: $cl-heading-blue;
        }
            
        h2 {
            background: $cl-heading-blue;

            &::after {
                border-bottom: 42px solid $cl-heading-blue;
            }
        }
    }

    &.page-heading-cyan {
        &::after {
            background: $cl-heading-cyan;
        }
            
        h2 {
            background: $cl-heading-cyan;

            &::after {
                border-bottom: 42px solid $cl-heading-cyan;
            }
        }
    }

    &.page-heading-green {
        &::after {
            background: $cl-heading-green;
        }
            
        h2 {
            background: $cl-heading-green;

            &::after {
                border-bottom: 42px solid $cl-heading-green;
            }
        }
    }

    &.page-heading-green-mb {
        &::after {
            background: $cl-green;
        }
            
        h2 {
            background: $cl-green;

            &::after {
                border-bottom: 42px solid $cl-green;
            }
        }
    }

    &.page-heading-green-cyan {
        &::after {
            background: $cl-green3;
        }
            
        h2 {
            background: $cl-green3;

            &::after {
                border-bottom: 42px solid $cl-green3;
            }
        }
    }

    &.page-heading-entertain {
        &::after {
            background: #0072ca;
        }
            
        h2 {
            background: #0072ca;

            &::after {
                border-bottom: 42px solid #0072ca;
            }
        }
    }
}

.header {
    &-top {
        padding: 10px 0 4px;
        background-color: $cl-green;

        &-item {
            font-size: 14px;
            transition: all .2s ease;
            cursor: pointer;

            img {
                max-width: 24px;
                max-height: 24px;
                object-fit: cover;
                margin-bottom: 4px;
            }

            &:hover span {
                color: $cl-green3;
            }
        }
    }

    &-logo {
        img {
            max-width: 250px;
            max-height: 60px;
        }

        &-multimedia {
            img {
                max-width: 150px;
            }
        }
    }

    &-links {
        li {
            color: $cl-white;

            a {
                color: $cl-white;
            }
        }
    }
}

.card-title {
    font-family: 'Helvetica Bold', Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.page-heading,
.tab-news .nav-item,
.home-slider-carousel .card-title,
.home-slider-carousel span,
.home-multimedia li,
.home-multimedia .owl-carousel .card-title,
.large-block .card-title,
.card-category,
.card-category-horizontal,
.card-category-horizontal-red,
.card-category-horizontal-green,
.card-category-horizontal-green-light,
.home-travel-header,
.footer-links,
.header-category,
.multimedia-banner-meta span,
.multimedia-heading,
.font-utm .card-title  {
    font-family: $font-utm;
}

.menu {
    font-family: $font-utm;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    padding: 4px 0;

    .nav-link {
        color: $cl-black;
        text-transform: uppercase;
        font-size: 17px;
    }

    .nav-item {
        transition: all .3s ease;
        border-bottom: 2px solid transparent;

        &:hover {
            border-bottom: 2px solid $cl-green;
        }

        &:hover .sub-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}

.sub-menu {
    position: absolute;
    background: $cl-green;
    padding: 8px 16px;
    top: 100%;
    left: 0;
    z-index: 2;
    width: 100vw;
    border-radius: 3px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    transform: translateY(30px);
    display: flex;
    justify-content: center;

    a {
        color: $cl-white;
        transition: all .2s ease;
        padding: 3px 10px;


        &:hover {
            padding-left: 5px;
        }
    }
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: .1rem 1rem;
    transition: all .2s ease;
    letter-spacing: -.3px;

    &:hover {
        color: $cl-green;
    }
}

@media (min-width: 1441px) {
    .menu {
        .nav-link {
            font-size: 20px;
        }
    }
    
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 10px;
        padding-right: 10px;
        letter-spacing: 0;
    }
}

header {
    position: relative;
    transition: all .3s ease;

    &.fixed {
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;

        .menu {
            background-color: $cl-white;
        }

        #category-menu {
            .menu {
                background-color: $cl-green;
            }
        }
    }
}

.form-control {
    &:focus {
        outline: 0;
        box-shadow: none;
        border-color: $cl-green;
    }
}

.page-search {
    background: $cl-white;
    padding: 10px;
    position: absolute;
    width: 30vw;
    left: 50%;
    top: 76px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;
    transform: translateX(-50%);

    &.show {
        opacity: 1;
        visibility: visible;
    }

    .form-control {
        width: calc(100% - 50px);
    }

    img {
        max-width: 24px;
    }

    .btn {
        background-color: $cl-green;
        border: 1px solid $cl-green;
    }
}

.menu-category {
    position: fixed;
    background: $cl-green;
    width: 100vw;
    z-index: 100;
    top: 74px;
    overflow-y: auto;
    right: 0;
    transition: all .3s ease;
    padding-left: 0;
    opacity: 0;
    visibility: hidden;
    display: none;

    &.show {
        opacity: 1;
        visibility: visible;
        display: block;
    }

    .menu {
        box-shadow: none;
        float: right;
    }

    .navbar-nav {
        width: 270px;
    }

    .close {
        padding: 10px;
        position: absolute;
        z-index: 100;

        span {
            font-size: 20px;
            color: $cl-white;
            background: $cl-green;
            opacity: 1;
            padding: 5px;
            border-radius: 100%;
            display: block;
            min-width: 30px;
            height: 30px;
        }
    }

    .nav-item {
        position: relative;
        border-bottom: 1px solid $cl-white;

        .nav-link {
            padding-top: 7.5px;
            padding-bottom: 7.5px;
        }

        &:hover {
            border-bottom: 1px solid $cl-white;
        }

        > ul {
            list-style: none;
            position: absolute;
            right: 270px;
            top: 0;
            width: calc(100vw - 270px);
            background-color: #eee;
            z-index: 10;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            padding-left: 0;
            align-items: center;
            transition: all .3s ease;

            li {
                margin-right: 16px;

                &:first-child {
                    margin-left: 16px;
                }
            }
        }

        &:hover > ul {
            background: $cl-white;
        }
    }

    .nav-link {
        color: $cl-white;
    }

    .nav-item:hover {
        .nav-link {
            color: $cl-white;
            padding-left: 15px;
        }
    }

    &-home {
        left: 0;
        right: 0;
        padding-left: 0;

        .nav-item > ul {
            left: 270px;
            right: unset;
            justify-content: flex-start;
        }

        .close {
            position: relative;
        }

        &.show {
            opacity: 1;
            visibility: visible;
            display: block;
        }

        .menu {
            float: none;
        }
    }
}

.home-slider-carousel {
    .card-title {
        position: absolute;
        bottom: 8px;
        left: 8px;
        width: 100%;
        padding: 8px;
        background: url(../images/bg-slider.png) no-repeat right;

        > a {
            line-height: 40px;
        }
    }

    .owl-dots {
        bottom: 50%;
        display: flex;
        flex-direction: column;

        .owl-dot {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    .img-social {
        max-width: 32px;
    }
}

.large-block {
    .card-title {
        font-size: 40px;
        text-transform: uppercase;
    }

    img {
        min-height: 420px;
        object-fit: cover;
    }
}

.home-travel {
    background: $cl-green;
    padding: 32px 0;
    margin-bottom: 80px;

    &-header {
        font-size: 40px;
        text-align: center;
        color: $cl-white;
        margin-bottom: 24px;

        span {
            color: $cl-blue2;
        }
    }

    .card {
        background-color: transparent;
    }

    .horizontal-block {
        background: $cl-blue;
        padding: 10px;

        .card-title {
            font-size: 22px;
        }
    }
}

.home-multimedia {
    background: $cl-green;
    padding: 16px 0;
    margin-bottom: 80px;

    .owl-carousel {
        .card-title {
            font-size: 40px;
        }
    }

    .multimedia-vertical {
        background: $cl-blue;
        padding: 10px;

        .card-text {
            @include truncate(7);
        }
    }
}

.owl-carousel {
    .icon-play-large {
        width: 50px !important;
    }
}

.list-news-item {
    li {
        min-height: 38px;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }
}

.widget-block {
    &-bottom {
        margin-bottom: 120px;
    }

    .news-item {
        &-img {
            position: relative;
            overflow: hidden;

            img {
                transition: all .2s ease;
                transform: scale(1);
            }
        }

        &:hover &-img img {
            transform: scale(1.2);
        }
    }

    .news-item-bg {
        background-color: $cl-green2;

        .news-item-img {
            padding: 5px;
        }
    }
}

.footer {
    background: $cl-green;
    padding: 24px 0 56px;

    &-logo img {
        max-width: 300px;
    }

    &-links {
        text-transform: uppercase;
        font-size: 18px;

        li { 
            line-height: 18px;

            &:not(:last-child) {
                border-right: 1px solid $cl-white;
                padding-right: 14px;
            }
        }

        a {
            color: $cl-white;
        }
    }

    &-social {
        ul {
            min-width: 180px;
        }
        img {
            max-width: 40px;
        }
    }
}

// Style for multimedia page

.main-multimedia {
    background-color: #00554f;
}

.multimedia-banner {
    position: relative;
    padding-top: 16px;
    margin-bottom: 32px;

    h1 {
        font-size: 100px;
        width: 300%;
        margin-bottom: 60px;
        font-family: $font-utm;
        letter-spacing: 2px;
    }

    h2 {
        font-family: $font-utm;
        font-size: 40px;
    }

    &-left {
        position: relative;
        z-index: 2;
        padding-top: 60px;
    }

    &-meta {
        position: absolute;
        right: 0;
        bottom: 0;

        img {
            max-width: 30px;
        }
    }
}

.multimedia-heading {
    color: $cl-green2;
    margin-bottom: 24px;
    text-align: center;
}

// Style for category page
.header-category {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 5px;
}

.page-detail,
.sidebar,
.page-category-content {
    .list-news-item li {
        min-height: unset;
    }

    &-meta {
        img {
            max-width: 28px;
        }
    }
}

.page-detail-content {
    h2,
    h5,
    h6,
    .user-name {
        font-family: 'Helvetica Bold', Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
}

.page-detail-links {
    font-family: $font-calibri-bold;
}

.icon-hotnews {
    max-width: 50px;
    max-height: 50px;
    object-fit: cover;
}

// Style for mobile
.category-mb-top {
    .large-text,
    .title-text {
        font-family: $font-utm;
        font-size: 24px;
    }

    .title-text {
        font-size: 40px;
    }
}

.news-center {
    .owl-carousel .owl-stage-outer {
        overflow: visible;
    }
}

.js-news {
    .owl-stage-outer {
        margin-left: -6px;
    }
}

.icon-play {
    width: 18px;
    margin-top: -2px;
    margin-right: 8px;
}

.icon-play-large {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 50px;
}

.icon-play-medium {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 32px;
}

@media (max-width: 769px) {
    body, html {
        width: 100%;
        overflow-x: hidden;
    }

    .menu-category {
        top: 0;
    }

    .menu-category .navbar-nav {
        width: 100vw;
    }

    .menu-category .close {
        right: 0;
    }

    .page-search {
        width: 100vw;
        left: 0;
        transform: none;
    }

    .home-multimedia .owl-carousel .card-title {
        font-size: 22px;
    }

    .nav-pills .nav-item:last-child .nav-link {
        background-color: $cl-green2;
    }

    .nav-pills .nav-item:last-child .nav-link::after {
        border-bottom: 40px solid $cl-green2;
    }

    .page-search {
        top: 70px;
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    }

    .home-mb-slider {
        .card-title {
            font-family: $font-utm;
        }
        .owl-dots {
            bottom: 145px;
        }
    }

    .page-detail-img .icon-play-large {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .page-detail-img {
        .caption-img {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background: #c6c6c6;
            text-align: center;
            padding: 4px 0;
        }
    }

    .card-text {
        @include truncate(4);
    }

    .header-top {
        padding: 8px 0;
    }

    .header-top-item img {
        max-width: 48px;
        max-height: 32px;
    }

    .header-logo img {
        max-width: 270px;
    }

    .footer-social ul {
        min-width: unset;
    }

    .widget-block {
        .news-item {
            border-radius: 0;
            box-shadow: 0 0 5px rgba(0, 0, 0, .2);

            .card-title {
                margin-bottom: 0;
                @include truncate(3);
            }
        }
    }

    .home-multimedia {
        background: $cl-green;
        padding: 8px 0 24px;
    }

    .most-read {
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);
        padding: 16px;

        .news-meta {
            font-family: $font-calibri;
        }

        ul > li {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    .widget-society {
        .card-body,
        .news-item-img {
            width: 50%;
        }

        [class*='col-'] {
            &:nth-child(2) {
                .card-body {
                    width: 55%;
                }
            }
        }

        .card-text {
            @include truncate(3);
        }
    }

    .hot-news {
        p {
            font-size: 18px;
            line-height: 18px;
        }
    }
}

.owl-carousel .owl-item img {
    width: inherit;
}

@media (max-width: 576px) {
    .header-logo img {
        max-width: 200px;
    }

    .home-multimedia {
        [class*="col-"] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .page-search {
        top: 55px;
    }

    .category-mb-top .w-50, .widget-block .w-50 {
        width: 35% !important;
    }

    .category-mb-top .card-body, .widget-block .card-body {
        padding: 10px;
    }

    .category-mb-top .card-title, .widget-block .card-title {
        font-size: 18px;
    }

    .widget-society .news-item-img {
        width: 35% !important;
    }

    .home-mb-slider {
        .owl-dots {
            bottom: 185px;
        }
    }

    .widget-block .news-item .card-title,
    .related-post .card-title,
    .category-mb-top .card-title {
        @include truncate(2);
    }


    .widget-society {
        .card-body,
        .news-item-img {
            width: 50%;
        }

        [class*='col-'] {
            &:nth-child(2) {
                .card-body {
                    width: 55%;
                }
            }
        }

        .card-text {
            display: none;
        }

        .card-body {
            padding: 12px;
        }

        .card-title {
            font-size: 1.1rem;
        }

        img {
            min-height: 120px;
            object-fit: cover;
        }
    }

    .related-post .vertical-list,
    .page-detail-content,
    .politic {
        [class*='col-'] {
            &:nth-child(even) {
                padding-left: 5px;
            }
    
            &:nth-child(odd) {
                padding-right: 5px;
            }
        }
    }

    .tab-news {
        &::after {
            top: 38px;
        }
    }

    .header-top-item img {
        max-width: 30px;
        max-height: 30px;
    }
    
    .nav-pills {
        .nav-item {
            position: relative;
    
            &:first-child {
                z-index: 2;
            }
    
            &:last-child {
                .nav-link {
                    padding-left: 32px;
                }
            }
        }
    
        .nav-link {
            font-size: 20px;
            padding: 4px 8px;

            &::before {
                left: 8px;
            }
    
            &::after {
                position: absolute;
                content: '';
                top: 0;
                right: -24px;
                width: 0;
                height: 0;
                border-bottom: 38px solid $cl-green;
                border-right: 24px solid transparent;
                transition: all .2s ease;
            }
    
            &.active {
                background: $cl-green;
                border-radius: 0;
    
                &:after {
                    border-bottom: 38px solid $cl-green;
                    z-index: 2;
                }
            }
        }
    }
}