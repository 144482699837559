// Variables Bootstrap 5

$cl-red:     #d84600;
$cl-heading-cyan: #009cd8;
$cl-heading-blue: #008ed8;
$cl-heading-green: #00d8ab;
$cl-green:   #007b73;
$cl-green2: #00d8c8;
$cl-green3: #00d7d8;
$cl-black:   #000;
$cl-white:   #fff;
$cl-blue: #007cad;
$cl-blue2: #00b2f8;


// Font
$font-calibri: 'Calibri', 'Roboto', "Helvetica Neue", san-serif;
$font-calibri-bold: 'Calibri Bold', 'Roboto', "Helvetica Neue", san-serif;
// $font-utm: 'UTM Babes', 'Roboto', "Helvetica Neue", san-serif;
$font-utm: 'UTM Bebas', 'Roboto', "Helvetica Neue", san-serif;
